import React, { useEffect } from 'react'
import {
  ApiResponse,
  deleteUser,
  getUsers,
  User,
  UsersResponse
} from '@utils/api'
import useApi from '@hooks/useApi'
import GenericPage from '@templates/Generic/Page'
import {
  Avatar,
  ButtonGroup,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText
} from '@mui/material'
import { Card, WithCaution, Button } from '@components'
import { navigate } from 'gatsby'
import { PersonAdd } from '@mui/icons-material'
import { getUserData } from '@utils/auth'
import { PlaceholderListItem } from '@components/Placeholder'
import useLicense from '@hooks/useLicense'

const SettingsUsersPage = () => {
  const { refreshLimits } = useLicense()
  const { response, makeRequest: refreshData } = useApi<UsersResponse>({
    apiMethod: getUsers
  })

  const deleteApi = useApi<ApiResponse<string>>({
    apiMethod: deleteUser,
    requestOnMount: false
  })

  const handleDelete = async (username: User['username']) => {
    await deleteApi.makeRequest(username)

    await refreshLimits()

    setTimeout(refreshData, 1000)
    deleteApi.enqueueSnackbar(`User ${username} successfully deleted.`, {
      variant: 'success'
    })
  }

  const me = getUserData()
  const users = response?.users || []

  // Track usage
  useEffect(() => {
    refreshLimits()
  }, [])

  return (
    <GenericPage
      title={'User Management'}
      maxWidth="md"
      breadcrumbs={[
        {
          title: 'Settings',
          to: '/settings/'
        },
        {
          title: 'Users'
        }
      ]}
      actions={
        <ButtonGroup
          color="primary"
          variant="outlined"
          aria-label="Host Actions"
        >
          <Button
            data-cy="addUser"
            feature="users"
            endIcon={<PersonAdd />}
            to="/settings/users/add/"
          >
            Add User
          </Button>
        </ButtonGroup>
      }
    >
      <Card heading="Users" feature="users" style={{ flexGrow: 1 }}>
        <List>
          {!response && (
            <>
              <PlaceholderListItem />
              <PlaceholderListItem />
            </>
          )}
          {users.map((user, index) => {
            const isSystem = user.roles.indexOf('system') > -1
            const isSelf = user.username === me.username
            return (
              <ListItem
                key={user.username}
                data-cy={`user_${user.username}`}
                button
                divider={index + 1 < users.length}
                onClick={() =>
                  navigate(`/settings/users/edit/${user.username}/`)
                }
              >
                <ListItemAvatar>
                  <Avatar>
                    {user.full_name.charAt(0).toUpperCase() || ''}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={`${user.username}${
                    user.full_name && ` (${user.full_name})`
                  }`}
                  secondary={`Role: ${user.roles.join(', ')}`}
                />
                <ListItemSecondaryAction>
                  <ButtonGroup
                    color="primary"
                    aria-label="outlined primary button group"
                    size="small"
                  >
                    <Button
                      data-cy={`edit_${user.username}`}
                      disabled={isSystem}
                      to={`/settings/users/edit/${user.username}/`}
                    >
                      Edit
                    </Button>
                    <Button
                      disabled={isSystem}
                      to={`/settings/users/reset/${user.username}/`}
                    >
                      Reset Password
                    </Button>
                    <WithCaution title="Permanently Deleting User">
                      <Button
                        data-cy={`delete_${user.username}`}
                        to="#"
                        disabled={isSystem || isSelf}
                        onClick={() => handleDelete(user.username)}
                      >
                        Delete
                      </Button>
                    </WithCaution>
                  </ButtonGroup>
                </ListItemSecondaryAction>
              </ListItem>
            )
          })}
        </List>
      </Card>
    </GenericPage>
  )
}

export default SettingsUsersPage
